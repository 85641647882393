import PriceTable from "../components/Table";
import FadeIn from 'react-fade-in';
import recronLogo from "../images/hiswa-recron-logo.jpg"
import {Link, useMatch, useResolvedPath} from "react-router-dom";

export default function Tarieven() {
    const hemelvaarText = ['Hemelvaart (18 mei t/m 21 mei)', <br />, 'en Pinksteren (26 mei t/m 29 mei):'];

    return (
        <FadeIn transitionDuration={1000}>
            <h1>Tarieven 2024</h1>
            <div className="tarievenTablesDiv">
                <div className="toeristenTableDiv">
                    <h4>Toeristische plaatsen (per nacht):</h4>
                    <PriceTable name="toeristenTable2024"/>  <br></br>
                    <h4>Oktoberarrangement:</h4>
                    <p>
                        Alle onderstaande prijzen zijn inclusief kampeermiddel, elektra, <br></br>auto op parkeerplaats, toeristenbelasting en milieuheffing.
                    </p>
                    <PriceTable name="oktoberTable" /> <br></br>
                    <h4>
                        Aankomst na 13:00 uur.<br/>
                        Vertrek voor 12:00 uur.
                    </h4>
                </div>
                <div className="huisjesTableDiv">
                    <h4>Huurprijzen vakantiewoning:</h4>
                    <PriceTable name="huisjesTable2024"/> 
                    <div id="huisjesTarievenText">
                        <p>
                            De huurprijs is <b>inclusief</b> verblijf voor maximaal 2 personen, 1x bedlinnen voor 2 personen,
                            energie- en watergebruik, toeristenbelasting en eindschoonmaak. Bij een langer verblijf 
                            dan 1 week kunt u een extra set bedlinnen huren. De kosten bedragen € 7,50 per persoon per set. 
                        </p>
                        <p>
                            Week = maandag tot maandag/vrijdag tot vrijdag (7 overnachtingen)<br/>
                            Weekeinde = vrijdag tot maandag (3 overnachtingen)<br/>
                            Midweek = maandag tot vrijdag (4 overnachtingen)
                        </p>
                        <p>
                            Bij annuleringen hanteren wij de <a target="_blank" rel="noreferrer" href="https://www.hiswarecron.nl/leisure-en-recreatie/voorwaarden-en-overeenkomsten">
                            <b>voorwaarden</b></a> van Hiswa Recron.
                        </p>
                    </div>
                    <img id="recronLogo" src={recronLogo} alt="recron logo"/>
                </div>
                {/* <Link to="/Tarieven"><button id="tarievenNieuwButton">Tarieven 2023</button></Link> */}
            </div>
        </FadeIn>
    )
}